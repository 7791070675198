import React from 'react';
import StarRatings from 'react-star-ratings';

import { Props } from './RatingStars.interface';

import s from './RatingStars.scss';

export default ({
    score,
    numberOfStars,
    starColor,
    starEmptyColor,
    starDimension,
    center = false,
}: Props): JSX.Element => (
    <div className={`${s.stars} ${center ? s.stars___center : ''}`}>
        <StarRatings
            rating={score}
            numberOfStars={numberOfStars || 5}
            starRatedColor={starColor || '#f0ac00'}
            starEmptyColor={starEmptyColor || '#c5c5c5'}
            starDimension={starDimension || '25px'}
            starSpacing="0"
            name="rating"
        />
    </div>
);
