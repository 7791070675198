import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/pro-regular-svg-icons';
import { CarouselProvider, Slider, Slide, Dot, ButtonBack, ButtonNext, WithStore } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import WindowResizeHook from 'hooks/resize/resize.hook';
import { Props, CarouselWatcherProps } from './Carousel.interface';

import s from './Carousel.scss';

// eslint-disable-next-line react/prefer-stateless-function
class CarouselWatcher extends React.Component<CarouselWatcherProps> {
    render() {
        const { carouselStore } = this.props;
        const state = carouselStore.getStoreState();
        if (state.currentSlide !== 0) {
            carouselStore.setStoreState({
                currentSlide: 0,
            });
        }

        return <></>;
    }
}

const CarouselWatcherDecorated = WithStore<CarouselWatcherProps>(CarouselWatcher);

const DotGroup = (props: any): JSX.Element => {
    const { totalSlides, visibleSlides } = props;
    const slideGroups = Math.ceil(totalSlides / visibleSlides);
    if (slideGroups <= 1) return <></>;
    return (
        <div className={s.carousel__dotgroup}>
            {[ ...Array(slideGroups) ].map((_, i) => (
                <Dot
                    // eslint-disable-next-line react/no-array-index-key
                    key={`slider-dot-${i}`}
                    slide={i * visibleSlides} aria-label={`Move Carousel to slide group ${i + 1}`}
                >
                    <span />
                </Dot>
            ))}
        </div>
    );
};

export default ({
    maxVisibleSlides, changeOnResize, mdSlidesToShow, width, height, children, hasArrows, dots,
}: Props): JSX.Element => {
    const dimensions = WindowResizeHook();
    const screenMd = dimensions.width > 1024;
    const screenSm = dimensions.width > 500;
    const visibleSlides = screenMd ?
        maxVisibleSlides : screenSm && changeOnResize ? mdSlidesToShow || 2 : 1;
    if (typeof window === 'undefined') return (<></>);

    return (
        <span className={`${s.carousel} ${s.gliderOverflow}`}>
            <CarouselProvider
                visibleSlides={visibleSlides}
                naturalSlideWidth={width}
                naturalSlideHeight={height}
                isIntrinsicHeight
                dragEnabled={!screenMd}
                touchEnabled={!screenMd}
                totalSlides={React.Children.count(children)}
                className={s.carousel}
                step={visibleSlides}
                dragStep={visibleSlides}
            >
                <CarouselWatcherDecorated windowWidth={dimensions.width} />
                <Slider className={hasArrows ? s.carousel__arrows : ''}>
                    {React.Children.map(children, (child, i) => <Slide key={i.toString(36)} index={i}>{child}</Slide>)}
                </Slider>
                {/* if dots is true always show it, if its undefined only show it when !screenmd */}
                {(dots || (!screenMd && dots === undefined)) &&
                    <DotGroup totalSlides={React.Children.count(children)} visibleSlides={visibleSlides} />
                }
                {((hasArrows && !screenMd) || (hasArrows && React.Children.count(children) > maxVisibleSlides)) &&
                    <>
                        <ButtonBack className={s.carousel__back}>
                            <FontAwesomeIcon icon={faAngleDoubleLeft} size="3x" />
                        </ButtonBack>
                        <ButtonNext className={s.carousel__next}>
                            <FontAwesomeIcon icon={faAngleDoubleRight} size="3x" />
                        </ButtonNext>
                    </>
                }
            </CarouselProvider>
        </span>
    );
};

export const Pane: FunctionComponent = ({ children }: any): JSX.Element => (
    <span className="glider-slide">
        {children}
    </span>
);
